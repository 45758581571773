.App {
  text-align: center;
}

.wrapper {
  padding-top: 20px;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 50px;
  font-family: 'Silkscreen';
}

@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  font-size: xx-large;
  color: #FF10F0;
  text-shadow: 2px 2px #21EF80;
  @media (min-width: 80em) {
    font-size: 60px;
  };
}

h2 {
  font-size: large;
  padding-bottom: 15px;
  @media (min-width: 80em) {
    font-size: 30px;
    margin-top: -15px;
  };
}

body {
  background: url(../public/images/repeated-square-dark.webp);
  background-repeat: repeat;
  scroll-padding-bottom: 48px;
  @media (min-width: 1440px) {
    font-size: 1.2rem;
  }
}

.main-subheading {
  color: #FF10F0;
  font-size: 24px;
}

.footerBar {
  background: url(../public/images/brick-wall.png);
  background-size: contain;
}

.welcomeLampost {
  background: url(../public/images/Lamp_post.png);
  background-repeat: no-repeat;
  background-size: 200px;
  min-height: 350px;
}

.welcomeTree {
  background: url(../public/images/tree.png);
  background-repeat: no-repeat;
  background-size: 290px;
  min-height: 350px;
}

.welcomeMan {
  background: url(../public/images/cartoon-man.webp);
  background-repeat: no-repeat;
  background-size: 200px;
  min-height: 290px;
}

.welcomeStarOne {
  background: url(../public/images/bouncing-star.gif);
  background-repeat: no-repeat;
  transform: rotate(345deg);
  -webkit-filter:brightness(50%);
  background-size: 42px;
  min-height: 42px;
  min-width: 42px;
}

.welcomeStarTwo {
  background: url(../public/images/bouncing-star.gif);
  background-repeat: no-repeat;
  transform: rotate(15deg);
  -webkit-filter:brightness(50%);
  background-size: 36px;
  min-height: 36px;
  min-width: 36px;
}

.welcomeStarThree {
  background: url(../public/images/bouncing-star.gif);
  background-repeat: no-repeat;
  transform: rotate(355deg);
  -webkit-filter:brightness(50%);
  background-size: 30px;
  min-height: 30px;
  min-width: 30px;
}

.challengeContainer {
  padding: 40px;
}

.slick-slide img {
  margin: auto;
}

.slick-slide:not(.slick-current) {
  -webkit-filter:brightness(50%);
}

[data-testid='flowbite-progress-outer-label-container'] {
  float: right;
  color: #FF10F0;
  text-shadow: 1px 2px #21EF80;
  font-size: x-large;
  @media (min-width: 80em) {
    font-size: 40px;
  };
}

g>text {
  color: white;
  fill: white;
}

.google-visualization-tooltip g>text {
  fill: black !important;
}